import { Options, Vue } from "vue-class-component";
import { IDepartment } from "@/types/Department";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { hourDiff, notify } from "@/services/helpers";
import Datepicker from "@vuepic/vue-datepicker";
import AuthStore from "@/store/auth-store";

@Options({
  name: "EditDepartment",
  methods: { hourDiff },
  components: {
    Datepicker,
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        name: yup
          .string()
          .required("Please provide a name.")
          .min(3, "Must be at least 3 characters in length.")
          .max(20, "Must not exceed 20 characters in length."),
      }),
      schema2: yup.object().shape({
        startTime: yup.string().required("Please select the start time."),
        endTime: yup.string().required("Please select the end time."),
      }),
    };
  },
  watch: {
    initStartTime() {
      if (this.initStartTime) {
        this.newShift.startTime =
          this.initStartTime.hours + ":" + this.initStartTime.minutes;
        this.calcHour();
      }
    },
    //

    initEndTime() {
      if (this.initEndTime) {
        this.newShift.endTime =
          this.initEndTime.hours + ":" + this.initEndTime.minutes;
        this.calcHour();
      }
    },
  },
})
export default class EditDepartment extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  editDepartment: IDepartment = {
    id: 0,
    name: "",
    isActive: false,
    employeesCount: 0,
  };
  crumbs: any = ["Dashboard", "Department", "Edit Department"];
  loading = false;

  // shift
  roles = AuthStore.getTokenData().Roles;
  orgId = AuthStore.getTokenData().OrganisationId;
  totalHour = "";
  initStartTime = null as any;
  initEndTime = null as any;
  shiftLoading = false;
  isCreating = false;
  isEditing = false;
  shiftList = [];
  newShift = {
    id: 0,
    startTime: "",
    endTime: "",
    organisationId: this.orgId,
    departmentId: 0,
  };

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  message = "";
  async save() {
    this.loading = true;
    this.workForceService
      .postOrPut<void>(
        `/departments/${this.editDepartment.id}`,
        this.editDepartment as IDepartment,
        this.editDepartment.id.toString()
      )
      .then((response: any) => {
        if (!response.isError) {
          notify("Department updated successfully.", "Success", "success");
          this.goToRoute("/admin/departments");
        } else {
          notify(response.errors, "Error", "danger");
        }
        this.loading = false;
      });
  }

  resetShiftFlags() {
    this.isCreating = false;
    this.isEditing = false;
    this.newShift.id = 0;
    // this.newShift["name"] = "";
    this.newShift.organisationId = 0;
    this.newShift.startTime = "";
    this.newShift.endTime = "";
    this.totalHour = "";
    this.initStartTime = null;
    this.initEndTime = null;
  }

  async deleteShift(id: any) {
    const modal = await (this.$refs["confirmDialogue"] as any).show({
      title: "Deleting Shift!",
      message: "Are you sure you want to delete shift",
      okButton: "Delete",
      cancelButton: "Cancel",
      icon: "danger",
      type: "confirmation",
    });

    if (modal) {
      this.shiftLoading = true;
      this.workForceService.delete(`/Shifts/${id}`).then((response: any) => {
        if (!response.isError) {
          this.shiftLoading = false;
          notify("Shift deleted successfully.", "Success", "success");
          this.fetch();
        } else {
          notify(response.errors, "Error", "danger");
          this.shiftLoading = false;
        }
      });
    } else {
      this.shiftLoading = false;
      return false;
    }
  }

  editShift(item: any) {
    this.isEditing = true;
    this.newShift.id = item.id;
    this.newShift.organisationId = this.orgId;
    this.newShift.startTime = item.startTime;
    this.newShift.endTime = item.endTime;

    const startTime = item.startTime.split(":");
    const endTime = item.endTime.split(":");

    this.initStartTime = {
      hours: startTime[0],
      minutes: startTime[1],
      seconds: 0,
    };

    this.initEndTime = {
      hours: endTime[0],
      minutes: endTime[1],
      seconds: 0,
    };

    this.isCreating = true;
  }
  async saveShift() {
    if (this.totalHour == "00:00") {
      notify("Total Hour Cannot be 00:00.", "Error", "danger");
    } else {
      this.shiftLoading = true;
      this.workForceService
        .postOrPut<void>(
          `/Shifts${this.isEditing ? "/" + this.newShift.id : ""}`,
          this.newShift,
          this.isEditing ? this.newShift.id.toString() : undefined
        )
        .then((response: any) => {
          if (!response.isError) {
            this.fetch().then(() => {
              this.shiftLoading = false;
              this.isCreating = false;
              this.isEditing = false;
              this.resetShiftFlags();
              notify("Shift added/updated successfully.", "Success", "success");
            });
          } else {
            this.shiftLoading = false;
            notify(response.errors, "Error", "danger");
          }
        });
    }
  }

  // fetch shift list
  async fetch() {
    await this.workForceService
      .get(`/Shifts/departments/${this.editDepartment.id}`, false)
      .then((response: any) => {
        this.shiftList = response.content;
      });
  }

  formatTime() {
    if (this.initStartTime) {
      const startHourCount = this.initStartTime.hours.toString().length;
      const StartMinCount = this.initStartTime.minutes.toString().length;
      const sHour =
        startHourCount == 1
          ? "0" + this.initStartTime.hours
          : this.initStartTime.hours;
      const sMin =
        StartMinCount == 1
          ? "0" + this.initStartTime.minutes
          : this.initStartTime.minutes;
      this.newShift.startTime = sHour + ":" + sMin;
    }

    if (this.initEndTime) {
      const endHourCount = this.initEndTime.hours.toString().length;
      const endMinCount = this.initEndTime.minutes.toString().length;
      const eHour =
        endHourCount == 1
          ? "0" + this.initEndTime.hours
          : this.initEndTime.hours;
      const eMin =
        endMinCount == 1
          ? "0" + this.initEndTime.minutes
          : this.initEndTime.minutes;
      this.newShift.endTime = eHour + ":" + eMin;
    }
  }
  calcHour() {
    this.formatTime();
    if (this.newShift.startTime && this.newShift.endTime) {
      this.totalHour = hourDiff(this.newShift.startTime, this.newShift.endTime);
    }
    this.totalHour = this.totalHour != "NaN:NaN" ? this.totalHour : "-:-";
  }

  async initialize() {
    const deptId = atob(this.$route.params.departmentId.toString());
    await this.workForceService
      .get<IDepartment>(`/departments/${deptId}`, false)
      .then((response: any) => {
        this.editDepartment = response.content;
      });

    await this.fetch();

    this.calcHour();
  }
  async created() {
    await this.initialize();
    this.newShift.departmentId = this.editDepartment.id;
  }
}

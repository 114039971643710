<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <!--    Page Title-->
    <page-title>
      <template #title>
        <span class="h4 text-primary">Edit Department</span>
      </template>
    </page-title>

    <div class="row pt-2">
      <div class="col-12">
        <Form @submit="save" v-slot="meta" :validation-schema="schema">
          <div class="row justify-content-center">
            <div class="col-xs-12 col-md-6 form-group">
              <label>Department Name</label>
              <input-field icon="id-card">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    id="name"
                    :class="meta.errors.name ? 'is-invalid' : ''"
                    v-model="editDepartment.name"
                  />
                  <ErrorMessage name="name" class="invalid-feedback" />
                </template>
              </input-field>
            </div>

            <!--          Status Dropdown-->
            <div class="col-xs-12 col-md-4 form-group">
              <label>Status</label>
              <div>
                <label
                  :class="`btn btn-xl mr-2 ${
                    editDepartment.isActive === true
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                  }`"
                >
                  <input
                    type="radio"
                    hidden
                    :value="true"
                    v-model="editDepartment.isActive"
                    name="Status"
                    autocomplete="off"
                  />
                  Active
                </label>
                <label
                  :class="`btn btn-xl mr-2 ${
                    editDepartment.isActive === false
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                  }`"
                >
                  <input
                    type="radio"
                    hidden
                    :value="false"
                    v-model="editDepartment.isActive"
                    name="Status"
                    autocomplete="off"
                  />
                  Deactivate
                </label>
              </div>
            </div>
          </div>

          <!--        Buttons-->
          <div class="row mb-5 mt-3 justify-content-center">
            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="button"
                :isPrimary="true"
                class="btn-secondary btn-lg-cus"
                @click.prevent="this.$router.back()"
              >
                Cancel
              </button>
            </div>

            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="submit"
                class="btn-primary btn-lg-cus"
                :isPrimary="true"
                :disabled="loading"
              >
                <span>Save </span>
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div
                v-if="message"
                v-html="message"
                class="alert alert-danger small"
              ></div>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <template
      v-if="roles == 'Org Admin' || roles == 'Manager' || roles == 'Admin'"
    >
      <page-title>
        <template #title>
          <span class="h4 text-primary">Shifts</span>
        </template>

        <template #button>
          <div class="">
            <button
              v-if="!isCreating"
              class="btn btn-primary"
              @click.prevent="isCreating = true"
            >
              <font-awesome-icon class="mr-1" icon="plus-circle" />
              New Shift
            </button>
          </div>
        </template>
      </page-title>

      <Form @submit="saveShift" v-slot="meta" :validation-schema="schema2">
        <!--      Page Body-->
        <div class="row">
          <!--          New-->
          <div
            class="col-12 mb-5"
            v-show="isCreating"
            v-if="
              roles == 'Org Admin' || roles == 'Manager' || roles == 'Admin'
            "
          >
            <div class="row mt-3">
              <div class="col-12 mb-2">
                <span class="h6 rounded-sm py-1 px-2 bg-secondary">
                  Create / Edit Shift
                </span>
              </div>
              <div class="col-lg-6 col-xl-3 col-md-8 col-sm-12 mx-auto">
                <label class="required">Start Time</label>
                <Datepicker
                  uid="setStartTime"
                  :inputClassName="`dp-lg shadow-sm`"
                  calendarCellClassName="dp-round-cell"
                  menuClassName="dp-menu time"
                  v-model="initStartTime"
                  placeholder="Set start time"
                  timePicker
                  format="HH:mm"
                  :clearable="false"
                >
                  <template #input-icon>
                    <span class="px-3">
                      <font-awesome-icon class="text-primary" icon="clock" />
                    </span>
                  </template>
                </Datepicker>

                <Field
                  name="startTime"
                  id="startTime"
                  type="hidden"
                  format="HH:mm"
                  :class="meta.errors.startTime ? 'is-invalid' : ''"
                  v-model="newShift.startTime"
                />
                <ErrorMessage name="startTime" class="invalid-feedback" />
              </div>

              <div class="col-lg-6 col-xl-3 col-md-8 col-sm-12 mx-auto">
                <label class="required">End Time </label>
                <Datepicker
                  uid="setEndTime"
                  :inputClassName="`dp-lg shadow-sm`"
                  calendarCellClassName="dp-round-cell"
                  menuClassName="dp-menu time"
                  placeholder="Set end time"
                  v-model="initEndTime"
                  timePicker
                  format="HH:mm"
                  :clearable="false"
                >
                  <template #input-icon>
                    <span class="px-3">
                      <font-awesome-icon class="text-primary" icon="clock" />
                    </span>
                  </template>
                </Datepicker>
                <Field
                  name="endTime"
                  id="endTime"
                  type="hidden"
                  :class="meta.errors.endTime ? 'is-invalid' : ''"
                  v-model="newShift.endTime"
                  format="HH:mm"
                />
                <ErrorMessage name="endTime" class="invalid-feedback" />
              </div>

              <!--              Hours Count-->
              <div class="col-lg-6 col-xl-3 col-md-8 col-sm-12 mx-auto">
                <label>Total Hours</label>
                <input-field icon="clock">
                  <template v-slot:input>
                    <Field
                      readonly
                      type="text"
                      name="totalHour"
                      id="totalHour"
                      :class="meta.errors.totalHour ? 'is-invalid' : ''"
                      v-model="totalHour"
                    />
                    <ErrorMessage name="totalHour" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>

              <div class="col-12">
                <div v-if="message.length > 0" class="alert alert-danger small">
                  {{ message }}
                </div>
              </div>
            </div>

            <!--          Button-->
            <div class="row mt-4 justify-content-center">
              <div
                class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
              >
                <button
                  type="button"
                  class="btn-secondary btn-lg-cus"
                  @click.prevent="resetShiftFlags"
                >
                  Cancel
                </button>
              </div>

              <div
                class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
              >
                <button class="btn-primary btn-lg-cus" :disabled="shiftLoading">
                  <span
                    >{{ isCreating && !isEditing ? "Save" : "Update" }}
                  </span>
                  <span
                    v-show="shiftLoading"
                    class="spinner-border spinner-border-sm ml-1"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>

      <!--          Available shifts-->
      <div class="row">
        <div class="col-12">
          <template v-if="shiftList.length > 0">
            <div v-for="(item, index) in shiftList" v-bind:key="index">
              <card-row :avatar="false">
                <!--        Details-->
                <template #details>
                  <!--                  <div class="font-weight-bold text-primary">-->
                  <!--                    {{ item.name }}-->
                  <!--                  </div>-->

                  <div class="d-md-flex">
                    <div class="mr-3">
                      <span class=""> Start Time: </span>
                      <span class="badge badge-primary">
                        {{ item.startTime }}
                      </span>
                    </div>
                    <div class="">
                      <span class=""> End Time: </span>
                      <span class="badge badge-primary">
                        {{ item.endTime }}
                      </span>
                    </div>
                  </div>

                  <div class="">
                    <span class=""> Total Hours: </span>
                    <span class="badge badge-success-light">
                      {{ hourDiff(item.startTime, item.endTime) }}
                    </span>
                  </div>
                </template>

                <template
                  v-if="
                    roles == 'Org Admin' ||
                    roles == 'Manager' ||
                    roles == 'Admin'
                  "
                  #actions
                >
                  <!--                    edit-->
                  <span>
                    <button
                      class="btn btn-sm btn-primary mr-2"
                      :disabled="isCreating"
                      @click.prevent="editShift(item)"
                    >
                      <font-awesome-icon class="mr-1" icon="pencil-alt" />
                      Edit
                    </button>
                  </span>

                  <span>
                    <button
                      :disabled="isCreating"
                      @click.prevent="deleteShift(item.id)"
                      class="btn btn-sm btn-danger"
                    >
                      <font-awesome-icon class="mr-1" icon="trash" />
                      Delete
                    </button>
                  </span>
                </template>
              </card-row>
            </div>
          </template>
          <template v-else>
            <div class="card mb-3 shadow-sm">
              <div class="card-body border-0 rounded py-3">
                <span class="h6">No Shift Available</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
  <!--  Modal-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./edit-department.ts" />

<style scoped></style>
